<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageNotFound'
})
</script>

<template>
  <div class="container">
    <h1>404</h1>
    <p>Sidan hittades inte</p>
  </div>
</template>
